import { createRouter, createWebHashHistory } from "vue-router"
import Co2H20Midu from '../components/co2-h2o-midu'

const routes = [
    {
        path: '/',
        name: '/',
        component: Co2H20Midu,
        meta: {
            title: 'CO2咸水混合流体-密度'
        }
    },
    {
        path: '/co2h2omidu',
        name: 'co2h2omidu',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-h2o-midu'),
        meta: {
            title: 'CO2咸水混合流体-密度'
        }
    },
    {
        path: '/co2h2okuosan',
        name: 'co2h2okuosan',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-h2o-kuosan'),
        meta: {
            title: 'CO2咸水混合流体-扩散系数'
        }
    },
    {
        path: '/co2h2ojiemian',
        name: 'co2h2ojiemian',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-h2o-jiemian'),
        meta: {
            title: 'CO2咸水混合流体-界面张力'
        }
    },
    {
        path: '/co2h2ojiechujiao',
        name: 'co2h2ojiechujiao',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-h2o-jiechujiao'),
        meta: {
            title: 'CO2咸水混合流体-接触角'
        }
    },
    // {
    //     path: '/co2h2orongjiedu',
    //     name: 'co2h2orongjiedu',
    //     component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-h2o-rongjiedu'),
    //     meta: {
    //         title: 'CO2咸水混合流体-溶解度'
    //     }
    // },
    {
        path: '/co2h2oph',
        name: 'co2h2oph',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-h2o-ph'),
        meta: {
            title: 'CO2咸水混合流体-pH值'
        }
    },
    {
        path: '/co2chmidu',
        name: 'co2chmidu',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-ch-midu'),
        meta: {
            title: 'CO2烷烃混合流体-密度'
        }
    },
    {
        path: '/co2chjiemian',
        name: 'co2chjiemian',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-ch-jiemian'),
        meta: {
            title: 'CO2烷烃混合流体-界面张力'
        }
    },
    {
        path: '/co2chkuosan',
        name: 'co2chkuosan',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-ch-kuosan'),
        meta: {
            title: 'CO2烷烃混合流体-扩散系数'
        }
    },
    {
        path: '/co2chhunxiangyali',
        name: 'co2chhunxiangyali',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-ch-hunxiangyali'),
        meta: {
            title: 'CO2烷烃混合流体-混相压力'
        }
    },
    {
        path: '/co2ch4misan',
        name: 'co2ch4misan',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-ch4-nisan'),
        meta: {
            title: 'CO2CH4混合流体-弥散系数'
        }
    },
    {
        path: '/co2ch4midu',
        name: 'co2ch4midu',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-ch4-midu'),
        meta: {
            title: 'CO2CH4混合流体-密度'
        }
    },
    {
        path: '/co2ch4kuosan',
        name: 'co2ch4kuosan',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/co2-ch4-kuosan'),
        meta: {
            title: 'CO2CH4混合流体-扩散系数'
        }
    },
];
export const router = createRouter({
    history: createWebHashHistory(),
    base: 'ltxz',
    routes: routes
})
export default router;